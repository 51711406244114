import React from "react";
import harrisBuyouts from './Data/harrisBuyouts.json';
import { LayerGroup } from 'react-leaflet';
import MyCircleMarkers from "./MyCircleMarkers.js";

const harrisBuyoutsArray = Array.from(harrisBuyouts);

function MyMarkerGroup(props) {
  const from=props.from;
  const to=props.to;
  const tofrom=to+from;
return (
<div>
    <LayerGroup key={tofrom}>
      {harrisBuyoutsArray.filter(buyouts => buyouts.year >= from && buyouts.year <= to).map(buyouts => (
        <MyCircleMarkers from={from} to={to} buyouts={buyouts} />
      ))}
    </LayerGroup>
</div>
      );
    }

export default MyMarkerGroup;