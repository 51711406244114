import React from 'react';
import './App.css';
import MyMap from './Components/MyMap'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      yearSelect: '2001'
    };
    this.handleYearSelect = this.handleYearSelect.bind(this);
  }
  handleYearSelect(yearSelect) {
    this.setState({ "yearSelect": yearSelect });
  };
  
  render() {
  return (
    <div className="App">
      <MyMap
      yearSelect={this.state.yearSelect} />
    </div>
  );
}
}


export default App;
