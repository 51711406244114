import React, { useState} from "react";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import MyClusterGroup from './MyClusterGroup';
import MyMarkerGroup from "./MyMarkerGroup";
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const position = [29.82, -95.3104]

function MyMap() {
  const [from,setFrom] = useState(2000);
  const [to,setTo] = useState(2020);
  const classes = useStyles();

  const handleFrom = e => {
    setFrom(e.target.value);
  }

  const handleTo = e => {
    setTo(e.target.value);
  }
  
  return (
  <div>
      <MapContainer 
        center={position} 
        zoom={9} 
        style={{ height: 575, width: "100%" }}>
        <TileLayer
        attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
        url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png"
        />
        <LayersControl collapsed={false}>
        <LayersControl.BaseLayer checked name="Buyout Clusters">
        <MyClusterGroup from={from} to={to} />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer name="Buyout Coordinates">
        <MyMarkerGroup from={from} to={to} />
        </LayersControl.BaseLayer>
        </LayersControl>
      </MapContainer>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">From</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={from}
          onChange={handleFrom}
        >
          <MenuItem value={2000}>2000</MenuItem>
          <MenuItem value={2001}>2001</MenuItem>
          <MenuItem value={2002}>2002</MenuItem>
          <MenuItem value={2003}>2003</MenuItem>
          <MenuItem value={2004}>2004</MenuItem>
          <MenuItem value={2005}>2005</MenuItem>
          <MenuItem value={2006}>2006</MenuItem>
          <MenuItem value={2007}>2007</MenuItem>
          <MenuItem value={2008}>2008</MenuItem>          
          <MenuItem value={2009}>2009</MenuItem>
          <MenuItem value={2010}>2010</MenuItem>          
          <MenuItem value={2011}>2011</MenuItem>
          <MenuItem value={2012}>2012</MenuItem>          
          <MenuItem value={2013}>2013</MenuItem>
          <MenuItem value={2014}>2014</MenuItem>
          <MenuItem value={2015}>2015</MenuItem>
          <MenuItem value={2016}>2016</MenuItem>
          <MenuItem value={2017}>2017</MenuItem>
          <MenuItem value={2018}>2018</MenuItem>
          <MenuItem value={2019}>2019</MenuItem>
          <MenuItem value={2020}>2020</MenuItem>
        </Select>
        <FormHelperText>Select starting year for analysis.</FormHelperText>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">To</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={to}
          onChange={handleTo}
        >
          <MenuItem value={2000}>2000</MenuItem>
          <MenuItem value={2001}>2001</MenuItem>
          <MenuItem value={2002}>2002</MenuItem>
          <MenuItem value={2003}>2003</MenuItem>
          <MenuItem value={2004}>2004</MenuItem>
          <MenuItem value={2005}>2005</MenuItem>
          <MenuItem value={2006}>2006</MenuItem>
          <MenuItem value={2007}>2007</MenuItem>
          <MenuItem value={2008}>2008</MenuItem>          
          <MenuItem value={2009}>2009</MenuItem>
          <MenuItem value={2010}>2010</MenuItem>          
          <MenuItem value={2011}>2011</MenuItem>
          <MenuItem value={2012}>2012</MenuItem>          
          <MenuItem value={2013}>2013</MenuItem>
          <MenuItem value={2014}>2014</MenuItem>
          <MenuItem value={2015}>2015</MenuItem>
          <MenuItem value={2016}>2016</MenuItem>
          <MenuItem value={2017}>2017</MenuItem>
          <MenuItem value={2018}>2018</MenuItem>
          <MenuItem value={2019}>2019</MenuItem>
          <MenuItem value={2020}>2020</MenuItem>
        </Select>
        <FormHelperText>Select ending year for analysis.</FormHelperText>
      </FormControl>
  </div>
  );
}



export default MyMap;

