import React from "react";
import { CircleMarker, Tooltip } from "react-leaflet";

function MyCircleMarkers(props) {
  const buyouts=props.buyouts;
return (
        <CircleMarker
        radius={4}
        key={buyouts.TAXIDNO}
        center={[buyouts.lat, buyouts.long]}
        position={[buyouts.lat, buyouts.long]}
        color="orange">
        <Tooltip>
        {buyouts.year}
        </Tooltip>
        </CircleMarker>
      );
    }

export default MyCircleMarkers;